app.directive('paymentExistOrderInfo', [
  '$uibModal',
  'slFeatureService',
  'cartService',
  'logger',
  'staticResourceHost',
  function (
    $uibModal,
    slFeatureService,
    cartService,
    logger,
    staticResourceHost,
  ) {
    return {
      restrict: 'E',
      template:
        '<div class="payment-exist-order-hint" ng-bind-html="\'checkout.payments.exist.order.tip\' | translate: { order: duplicateOrder.orderNumber }"></div>',
      scope: {
        duplicateOrder: '=',
      },
      link: function (scope, element) {
        const hasMultiCheckout = slFeatureService.hasFeature('multi_checkout');

        element.on('click', '.order-number', function () {
          scope.openDialog();
        });

        scope.$on('$destroy', function () {
          element.off();
        });

        scope.openDialog = function () {
          $uibModal.open({
            templateUrl: hasMultiCheckout
              ? require('../../../../../public/themes/v1/default/views/templates.dialog.multi-checkout.payment-exist-order-info.html')
              : require('../../../../../public/themes/v1/default/views/templates.dialog.payment-exist-order-info.html'),
            controller: [
              '$scope',
              '$uibModalInstance',
              function ($scope, $uibModalInstance) {
                $scope.staticResourceHost = staticResourceHost;

                $scope.close = function () {
                  $uibModalInstance.close();
                };
                $scope.confirm = function () {
                  cartService
                    .destroyCart()
                    .then(function () {
                      window.location.href = `/orders/${scope.duplicateOrder.orderId}`;
                    })
                    .catch(function (error) {
                      logger.error(
                        'An error occurred while destroying the cart:',
                        error,
                      );
                    });
                };
              },
            ],
          });
        };
      },
    };
  },
]);
