app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('ms', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',
      'languages.id': 'bahasa Indonesia',

      'form.action.delete': 'Padam',
      'recaptcha.language': 'bm',

      'form.validation.required': '{{field_name}} diperlukan',
      'form.validation.maxlength': '{{field_name}} terlalu panjang',
      'form.validation.minlength': '{{field_name}} terlalu pendek',
      'form.validation.invalid': '{{field_name}} tidak sah',
      'form.validation.pattern': '{{field_name}} tidak sah',
      'form.validation.pattern.recipient-name':
        'Tidak dibenarkan menggunakan ruang dan simbol atau melebihi {{limit_length}} aksara.',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} mesti masukkan 10 nombor digit',
      'form.validation.email': '{{field_name}} tidak sah',
      'form.validation.unique':
        '{{field_name}} sudah digunakan. Sila pilih semula.',
      'form.validation.confirmation': 'Kata laluan tidak sepadan.',
      'form.validation.policy':
        'Anda mesti bersetuju dengan terma dan syarat untuk meneruskan.',
      'form.validation.delivery_option.outlying':
        'Jenis penghantaran yang anda pilih tidak tersedia untuk pulau-pulau pedalaman di Taiwan. Sila ubah alamat penghantaran anda atau pilih kaedah penghantaran yang lain.',
      'form.validation.delivery_option.local':
        'Jenis penghantaran yang anda pilih tidak tersedia untuk pulau-pulau di Taiwan. Sila ubah alamat penghantaran anda atau pilih kaedah penghantaran yang lain.',
      'form.validation.delivery_option.country':
        'Jenis penghantaran yang anda pilih tidak tersedia untuk negara ini. Sila ubah alamat penghantaran anda atau pilih kaedah penghantaran yang lain.',
      'form.validation.delivery_option.region':
        'Jenis penghantaran yang anda pilih tidak tersedia untuk rantau ini. Sila ubah alamat penghantaran anda atau pilih kaedah penghantaran yang lain.',
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'Birthday is invalid, should over {{minimumAgeLimit}} years old.',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',

      'dropdown.hint': 'Sila pilih',

      'session.signup.title': 'Daftar',
      'session.terms.facebook': 'Daftar dengan Facebook',
      'session.signup.facebook': 'Daftar Masuk Facebook',
      'sessions.or': 'ATAU',
      'session.terms':
        'Dengan meneruskan penggunaan perkhidmatan ini, anda bersetuju dengan terma dan syarat kami.',
      'session.signin.submit': 'Daftar Masuk',
      'session.forgot_password': 'Lupa Kata Laluan',
      'session.signin.title': 'Daftar Masuk',
      'session.signin.facebook': 'Daftar Masuk Facebook',
      'session.mobile_signup.check_mobile.title':
        'Sila semak nombor telefon bimbit anda adalah betul seperti berikut, sms akan dihantar kepada nombor telefon ini',
      'session.mobile_signup.check_mobile.your_number':
        'Nombor telefon bimbit anda',
      'session.mobile_signup.check_mobile.your_number.hint':
        'Sila masukkan nombor telefon bimbit Taiwan (bermula dengan 09)',
      'session.mobile_signup.check_mobile.send_code':
        'Sila hantar kod pemeriksaan',
      'session.mobile_signup.check_mobile.go_back.1': 'anda boleh',
      'session.mobile_signup.check_mobile.go_back.2': 'kembali',
      'session.mobile_signup.check_mobile.go_back.3':
        'untuk membetulkan nombor telefon bimbit sekiranya salah',
      'session.mobile_signup.input_code.hint':
        'Sila masukkan kod pemeriksaan yang anda terima',
      'session.mobile_signup.input_code.submit': 'Pergi',
      'session.mobile_signup.input_code.resend.countdown':
        'Hantar semula sms kod pemeriksaan ke telefon bimbit saya ({{resendCountdown}} saat)',
      'session.mobile_signup.input_code.resend':
        'Hantar semula sms kod pemeriksaan ke telefon bimbit saya',
      'session.forget_passowrd.check_phone.error':
        'Nombor telefon bimbit salah. Sila semak semula',
      'session.mobile_center.check_phone.error':
        'Nombor telefon bimbit sudah digunakan',

      'user.quick_signup.title': 'Jadi ahli sekarang!',
      'user.quick_signup.title.content':
        'Jangan terlepas sebarang tawaran eksklusif!',
      'user.quick_signup.promotion':
        'Saya mahu menerima berita dan promosi terbaru',
      'user.quick_signup.policy': 'Saya bersetuju dengan',
      'user.quick_signup.policy.url_content':
        'Terma Penggunaan dan Dasar Privasi kedai',
      'user.quick_signup.submit': 'Sertai Sekarang',
      'user.quick_signup.error.format': 'Ralat Format',
      'user.quick_signup.error.confirmed':
        'Nombor telefon bimbit ini sudah digunakan.',
      'user.quick_signup.error.confirmed.url': 'Daftar masuk sekarang.',
      'user.quick_signup.validate.title': 'Teruskan dengan Mendaftar',
      'user.quick_signup.validate.success':
        'Terima kasih kerana menyertai kami sebagai ahli! <br/> Anda boleh melengkapkan proses pendaftaran, <br/>atau ',
      'user.quick_signup.validate.success.shop': 'pergi membeli-belah sekarang',
      'user.quick_signup.validate.finish_hint':
        'Siapkan pendaftaran untuk menyemak dan menggunakan kredit kedai sekarang juga!',
      'user.quick_signup.validate.phone': 'Sahkan',
      'user.quick_signup.validate.send.sms': 'Hantar kod pengesahan',
      'user.quick_signup.validate.wrong.number':
        'Sekiranya nombor telefon bimbit tidak betul, sila',
      'user.quick_signup.validate.previous': 'kembali',
      'user.quick_signup.validate.modify': ' dan kemas kini',
      'user.quick_signup.validate.hint.password': 'Sekurang-kurangnya 8 aksara',
      'user.quick_signup.validate.hint.send':
        'Kod pengesahan dihantar. Anda boleh menghantar semula kod baharu selepas {{count}} saat.',
      'user.quick_signup.validate.hint.resend':
        'Belum menerima kod pemeriksaan? Sila klik di sini untuk menghantar semula.',
      'user.quick_signup.validate.submit': 'Selesaikan Pendaftaran',
      'user.quick_signup.validate.placeholder.sms': 'Masukkan kod pengesahan',
      'user.quick_signup.validate.placeholder.name': 'Nama',
      'user.quick_signup.validate.placeholder.password': 'Masukkan kata laluan',
      'user.sign_up.email_verification.dialog.title':
        'E-mel pengesahan dihantar kepada {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        'Sila sahkan e-mel sekarang dan alamat e-mel baharu akan dikemas kini. Jika anda tidak menerimanya, sila masukkan e-mel anda sekali lagi dan simpan.',
      'user.sign_up.email_verification.dialog.confirm': 'Sahkan',
      'user.delivery_data.recipient_phone': 'Nombor telefon penerima',

      'product.out_of_stock': 'Kehabisan stok',
      'product.addon_products.label': 'Item Tambahan',
      'product.bundled_products.label': 'Pakej',
      'product.bundle_group_products.label': 'A + B',
      'product.buyandget.label': 'Buy X Get Y',
      'product.addon_products.errors.main_product_out_of_stock':
        'Produk utama tidak mempunyai stok yang mencukupi',
      'product.addon_products.errors.reached_max_purchase_quantity':
        'Had {{ message }} setiap pesanan.',
      'product.addon_products.errors.addon_product_out_of_stock':
        'Item tambahan tidak mempunyai stok yang mencukupi',
      'product.addon_products.errors.larger_addon_product':
        'Bilangan item tambahan tidak boleh melebihi bilangan produk utama',
      'product.addon_products.errors.out_of_stock':
        'Stok tidak mencukupi untuk {{title}} produk tambahan.',
      'product.addon_products.tips.limit_exceed':
        'Apabila anda melaraskan kuantiti, bilangan item tambahan tidak boleh melebihi bilangan produk utama.',
      'product.addon_products.add_to_cart.hint':
        'Item berjaya ditambah ke dalam troli anda',
      'product.add_to_cart': 'Masukkan dalam Troli',
      'product.add_to_cart_fail': 'Your cart is full!',
      'product.buy_now': 'Beli Sekarang',
      'product.buy_now.preorder.hint': 'Now taking pre-orders',
      'product.buy_together': 'Beli sekali',
      'product.preorder_limit.hint':
        'The purchase quantity includes both in-stock and preordered items.',
      'product.coming_soon': 'COMING SOON',
      'product.available_time_over': 'AVAILABLE TIME OVER',
      'product.store_stock.check_stock': 'Check Stock Availability at Stores',
      'product.set.open_variation': 'View product details',

      'member.center.load.more': 'Seterusnya',
      'member.center.load.more.error':
        'Tidak dapat memuat data, sila cuba lagi.',

      'wishlist.signin_register': 'Daftar Sekarang',
      'wishlist.messages.remove_error':
        'Tidak dapat mengeluarkan item senarai idaman.',

      orders: 'Pesanan',
      order: 'Pesanan',
      'orders.fields.billing_address': 'Alamat Pengebilan',
      'orders.fields.order_number': 'No. Pesanan',
      'orders.fields.order_date': 'Tarikh',
      'orders.fields.order_status': 'Status',
      'orders.fields.order_remarks': 'Ringkasan Pesanan',
      'orders.fields.customer_name': 'Nama Pelanggan',
      'orders.fields.product_subscription_period': 'ke-{{period}}',
      'orders.fields.status.temp': 'Belum Selesai',
      'orders.fields.status.pending': 'Belum Selesai',
      'orders.fields.status.confirmed': 'Disahkan',
      'orders.fields.status.shipped': 'Dihantar',
      'orders.fields.status.completed': 'Selesai',
      'orders.fields.status.received': 'Diterima',
      'orders.fields.status.cancelled': 'Dibatalkan',
      'orders.fields.status.returning': 'Dipulangkan',
      'orders.fields.status.returned': 'Telah dipulangkan',
      'orders.fields.preorder_item': 'Item Pra pesanan',
      'orders.fields.customer': 'Pelanggan',
      'orders.fields.customer.phone': 'Nombor telefon pelanggan',
      'orders.fields.billto': 'Bil kepada',
      'orders.fields.payment_type': 'Jenis Pembayaran',
      'orders.fields.payment_instructions': 'Arahan Pembayaran',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        'Transaksi gagal: Amaun maksimum pesanan untuk Bayaran CVS adalah NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        'Transaksi gagal: Amaun bayaran minimum untuk Bayaran CVS adalah NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        'Transaksi gagal: Amaun maksimum pesanan untuk Bayaran Barcode adalah NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        'Transaksi gagal: Amaun bayaran minimum untuk Bayaran Barcode adalah NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'Had atas setiap transaksi menggunakan CVS ialah NTD20,000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'Had atas setiap transaksi menggunakan Barcode ialah NTD20,000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'Had atas setiap transaksi menggunakan WebATM ialah NTD30,000',
      'orders.fields.delivery_method': 'Kaedah Penghantaran',
      'orders.fields.delivery_description': 'Keterangan',
      'orders.fields.delivery_fee': 'Caj Penghantaran',
      'orders.fields.delivery_address': 'Alamat Penghantaran',
      'orders.fields.delivery_address.remarks': 'Ringkasan Penghantaran',
      'orders.fields.accept_terms.validation.required':
        'Anda mesti bersetuju dengan terma dan syarat untuk meneruskan',
      'orders.fields.options.blacklist.error':
        'Produk anda ada tersedia dengan kaedah penghantaran atau pembayaran yang lain. Sila pilih kaedah lain yang sah atau membuat pesanan sewajarnya.',
      'orders.show.message.thankyou.title':
        'Terima kasih kerana membuat pesanan',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': 'Teruskan membeli-belah sekarang!',

      'orders.show.message.shipped.title': 'Item anda telah dihantar',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': 'Jumlah kasar',
      'orders.payment_slip.order_info.instructions': 'Arahan pembayaran',
      'orders.payment_slip.order_info.read_more': 'Arahan View Full',
      'orders.payment_slip.upload_form.title':
        'Sila muat naikan butiran pembayaran selepas selesai bayaran',
      'orders.payment_slip.upload_form.hint':
        'Selepas mengesahkan pembayaran, kami akan menguruskan penghantaran secepat mungkin!',
      'orders.payment_slip.upload_form.notification':
        'Untuk mendapatkan pemberitahuan perintah terkini, sila layari',
      'orders.payment_slip.upload_form.image_input.title':
        'Muat naik butiran bayaran',
      'orders.payment_slip.upload_form.image_input.description':
        'Sila sahkan bahawa imej adalah jelas dengan termasuk nombor akaun, masa transaksi, jumlah transaksi',
      'orders.payment_slip.upload_form.image_input.change':
        'Klik untuk menukar gambar',
      'orders.payment_slip.upload_form.image_input.error':
        'Saiz gambar mestilah lebih kecil daripada 10MB',
      'orders.payment_slip.upload_form.paid_time': 'Masa pembayaran',
      'orders.payment_slip.upload_form.message': 'Nyatakan catatan pembayaran',
      'orders.payment_slip.upload_form.message.error':
        'Tidak boleh melebihi {{ n }} aksara',
      'orders.payment_slip.uploaded.alert':
        'Terima Kasih untuk butiran pembayaran, kami akan mengesahkan secepat mungkin!',
      'orders.payment_slip.uploaded.notification':
        'Langgan untuk mendapatkan maklumat order terkini.',
      'orders.payment_slip.uploaded.title':
        'Berikut di bawah adalah butiran pemindahan anda',
      'orders.payment_slip.uploaded.paid_time': 'Bayaran:',
      'orders.payment_slip.uploaded.upload_time': 'Kemas kini Masa Proof:',
      'orders.payment_slip.uploaded.order_link': 'Lihat Pesanan',
      'orders.payment_slip.uploaded_without_login.title':
        'Terima kasih! Maklumat pemindahan pembayaran telah dimuat naik untuk pesanan ini',
      'orders.payment_slip.uploaded_without_login.hint':
        'Sila log masuk untuk melihat butiran',
      'orders.payment_slip.footer':
        'Maklumat pembayaran anda mengisi hanya untuk pemeriksaan bayaran dan telah dilindungi dengan sistem sekuriti. Anda boleh membuat bayaran dengan lega.',

      'orders.index.no_records.title': 'Anda tidak mempunyai sebarang pesanan.',
      'orders.index.no_records.description':
        'Anda belum pernah berurus niaga di kedai ini.',
      'orders.index.no_records.action': 'Pergi Membeli-belah',
      'orders.index.title': 'Pesanan',
      'orders.index.action.view': 'Paparkan',
      'address.fields.address.recipient_name': 'Nama Penerima',
      'address.fields.address': 'Alamat',
      'address.fields.city': 'Bandar',
      'address.fields.postcode': 'Poskod',
      'address.fields.country': 'Negara',

      'orders.fields.subtotal': 'Jumlah kasar',
      'orders.fields.total': 'Jumlah ',
      'order.billing_address.same': 'Sama seperti alamat penghantaran',

      'orders.coupons.label': 'Kod Kupon',
      'orders.coupons': 'Tahniah! Anda layak mendapat diskaun berikut:',
      'orders.coupons.placeholder': 'Masukkan Kod Kupon',
      'orders.promotion_coupons.placeholder': 'Masukkan Kod Kupon',

      'orders.coupons.apply': 'Menggunakan kupon',
      'orders.fields.order_discount': 'Diskaun diberi',
      'orders.fields.order_custom_discount': 'Diskaun (khas)',
      'orders.coupons.invalid': 'Maaf, kupon anda tidak sah.',
      'orders.coupons.validation.minamount':
        'Maaf, pesanan anda tidak memenuhi nilai yang diperlukan untuk kupon ini',
      'orders.coupons.invalid.order':
        'Sila keluarkan kod kupon yang tidak sah sebelum membuat pesanan anda.',

      'orders.actions.title': 'Uruskan Status Pesanan',
      'orders.action.cancel': 'Batalkan Pesanan',
      'orders.action.confirm': 'Sahkan Pesanan',
      'orders.action.ship': 'Tanda sebagai Dihantar',
      'orders.general.title': 'Butiran pesanan',
      'orders.payment.title': 'Butiran pembayaran',
      'orders.delivery.title': 'Butiran penghantaran',
      'orders.products.title': 'Butiran Produk',

      'orders.fields.accept_terms':
        'Anda bersetuju dengan Terma Penggunaan dan Dasar Privasi Kedai',
      'orders.action.checkout': 'Buat Pesanan',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        'Nama Penerima',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        'Nama Kedai 7-11',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        'Kod Kedai 7-11',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        'Nama Penerima',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'Nama Kedai Family Mart',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'Kod Kedai Family Mart',
      'orders.fields.delivery_data.tw_ezship.location_code.hint':
        'Carian kedai',
      'orders.fields.delivery_data.tw_ezship.location_code.label': 'Kod Kedai',
      'orders.fields.delivery_data.tw_ezship.location_name.label': 'Nama Kedai',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        'Nama Penerima',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': 'ID Rujukan',

      // Checkout page error message field name
      'order.customer_name': 'Nama pelanggan',
      'order.email': 'Alamat e-mel',
      'order.customer_email': 'Alamat e-mel pelanggan',
      'order.customer_phone': 'Nombor telefon pelanggan',
      'order.delivery_data.recipient_name': 'Nama penerima',
      'order.delivery_data.recipient_phone': 'Nombor telefon penerima',
      'order.delivery_address.address_1': 'Alamat',
      'order.delivery_address.city': 'Bandar',
      'order.delivery_address.state': 'Daerah/Negeri/Wilayah',
      'order.delivery_data.location_code': 'Kod Kedai',
      'order.delivery_data.location_name': 'Nama Kedai',
      'order.delivery_data.time_slot': 'Masa Penghantaran',
      'order.delivery_data.scheduled_delivery_date': 'Tarikh Ketibaan',
      'order.delivery_data.time_slot_key': 'Slot Masa Ketibaan',
      'order.invoice.carrier_number': 'Nombor Plat Kenderaa',
      'order.invoice.mailing_address': 'Alamat',
      'order.invoice.tax_id': 'ID Cukai',
      'order.payment_data.holdername': 'Nama Penuh Pemegang Kad',
      'order.payment_data.expiry_date': 'Tarikh Luput',
      'order.payment_data.cvc': 'CVC',
      'order.payment_data.credit_card_number': 'Nombor Kad Kredit',
      'delivery.sfexpress.district': 'Daerah SF Ekspres',
      'delivery.sfexpress.store': 'Lokasi SF Ekspres',
      'delivery_address.region.hk': 'Rantau',
      'delivery_address.district.hk': 'Daerah',
      'delivery_address.region.tw': 'Negeri/Bandar',
      'delivery_address.district.tw': 'Daerah',
      'save_fields.customer_info.birthday': 'Tarikh lahir pelanggan',

      'delivery_options.fields.delivery_type': 'Jenis Penghantaran',
      'delivery_options.fields.delivery_types.pickup': 'Pungutan',
      'delivery_options.fields.delivery_types.local': 'Tempatan',
      'delivery_options.fields.delivery_types.email': 'E-mel',
      'delivery_options.fields.delivery_types.international': 'Antarabangsa',
      'delivery_options.fields.delivery_types.custom': 'Khas',
      'delivery_options.fields.delivery_types.tw_simple_711':
        'Pungutan 7-11 (TW sahaja)',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'Pungutan Family Mart (TW sahaja)',
      'delivery_options.fields.select_date': 'Tarikh Ketibaan',

      'ezship.error.invalid_input':
        'Satu atau lebih maklumat tidak betul atau tidak lengkap',
      'ezship.error.account_not_exist':
        'Akaun ezShip tidak wujud. Sila hubungi peniaga',
      'ezship.error.no_permission':
        'Akaun ezShip tidak mempunyai tahap kebenaran yang mencukupi',
      'ezship.error.no_easybag':
        'Akaun ezShip tidak mempunyai Easybag atau Minibag buat masa ini',
      'ezship.error.invalid_store': 'Kedai Pungutan tidak sah',
      'ezship.error.invalid_amount':
        'Amaun tidak sah. Amaun maksimum menggunakan ezShip adalah TWD 6000 untuk setiap pesanan.',
      'ezship.error.invalid_email': 'E-mel tidak sah',
      'ezship.error.invalid_mobile': 'Nombor telefon tidak sah',
      'ezship.error.invalid_name': 'Nama penerima tidak sah',
      'ezship.error.system_error':
        'Ralat sistem ezShip: Sila pilih kaedah penghantaran yang lain atau cuba sebentar lagi.',

      'payments.fields.types.paypal': 'Kad kredit atau Paypal',
      'payments.fields.types.paypal_express': 'Kad kredit atau Paypal',
      'payments.fields.types.credit_card': 'Kad kredit',
      'payments.fields.types.bank_transfer': 'Pembayaran melalui bank',
      'payments.fields.types.cash_on_delivery': 'Tunai Atas Penghantaran',
      'payments.fields.types.free_checkout': 'Semakan Keluar Percuma',
      'payments.fields.types.custom': 'Khas',
      'payments.fields.types.allpay_barcode': 'Barcode menerusi Allpay',
      'payments.fields.types.allpay_credit': 'Kad Kredit menerusi Allpay',
      'payments.fields.types.allpay_cvs': 'CVS menerusi Allpay',
      'payments.fields.types.allpay_webatm': 'WebATM menerusi Allpay',
      'payments.fields.types.asiapay': 'Kad Kredit menerusi Asiapay',
      'payments.fields.types.prizm': 'Kredit Visa / Master',
      'payments.fields.types.esun': 'Kad Kredit Esun',

      'payment.cc.expirydate': 'Tarikh Luput',
      'payment.cc.holdername': 'Nama Pemegang Kad',
      'payment.cc.number': 'Nombor Kad Kredit (Visa / Master)',
      'payment.cc.cvc': 'CVC',

      'payment.cc.expirydate.placeholder': 'MM/YY',
      'payment.cc.holdername.placeholder': 'Nama Pemegang Kad',
      'payment.cc.holdername.hint': 'Nama penuh sama pada kad',
      'payment.cc.number.placeholder': 'Nombor Kad Kredit (tiada ruang)',
      'payment.cc.cvc.placeholder': 'CVC',
      'payment.cc.cvc.hint':
        '3 angka kod keselamatan yang biasanya terdapat di belakang kad anda.',
      'payment.action.confirm': 'Sahkan',
      'payment.cc.update.instructions':
        'Sila masukkan kad kredit anda di bawah untuk mengemas kini',
      'payment.cc.fields.accept_terms':
        'Saya bersetuju dengan Terma Penggunaan dan Dasar Privasi',
      'payment.cc.fields.accept_terms.validation.required':
        'Anda mesti bersetuju dengan Terma dan Syarat untuk meneruskan',
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',

      'payments.fields.types.ezship': 'Bayar di Kedai Pungutan menerusi ezShip',

      cart: 'Troli Membeli-belah',
      'cart.title': 'Troli Membeli-belah',
      'cart.item.remove': 'Keluarkan',
      'cart.items.remove': 'Keluarkan',
      'cart.checkout': 'Semak keluar',
      'cart.empty': 'Troli anda kosong.',
      'cart.empty.description': 'Tambah produk ke troli untuk meneruskan.',
      'cart.empty.continue': 'Teruskan membeli-belah sekarang!',

      'checkout.instructions': 'Maklumat pesanan',
      'checkout.fields.email': 'E-mel Pelanggan',
      'checkout.fields.email.hint':
        'Sila isikan alamat e-mel anda dengan tepat supaya mesej pengesahan pesanan dapat dihantarkan kepada anda.',
      'checkout.fields.phone.hint':
        'Sila isikan nombor telefon bimbit anda untuk menerima perkembangan status penghantaran melalui SMS,',
      'checkout.fields.phone.invalid': 'Nombor telefon tidak sah',
      'checkout.fields.email.placeholder': 'Alamat E-mel',
      'checkout.fields.remarks': 'Ringkasan untuk Kedai',
      'checkout.fields.remarks.placeholder':
        'Tinggalkan ringkasan pesanan untuk peniaga',
      'checkout.fields.delivery_method': 'Kaedah Penghantaran',
      'checkout.fields.out_of_stock':
        'Maaf, stok tidak mencukupi. Sila keluarkannya daripada troli dan semak keluar semula.',
      'checkout.fields.phone': 'Nombor telefon',
      'checkout.fields.phone.placeholder': 'Masukkan nombor telefon anda',
      'checkout.fields.add_new': 'Tambah baru...',
      'checkout.fields.select': 'Pilih satu...',

      'checkout.delivery_option.title': 'Alamat Penghantaran',
      'checkout.instalment_amount':
        'Kira-kira {{amount}} x {{period}} bayaran ansuran',
      'checkout.labels.promotion.free_shipping': 'Penghantaran percuma',
      'checkout.labels.promotion.coupon': 'Kupon',
      'checkout.labels.promotion.promotion': 'Promosi',
      'checkout.labels.promotion.membership': 'Promosi ahli',

      'checkout.payment_method.title': 'Pembayaran',

      'checkout.discount.applied_promotions': 'Promosi Digunakan',
      'checkout.discount.applied_coupons': 'Kupon Digunakan',

      'checkout.promotion.tags.promotion': 'Promosi',
      'checkout.promotion.tags.coupon': 'Kupon',
      'checkout.promotion.tags.membership': 'Tawaran Ahli',
      'checkout.promotion.tags.free_shipping': 'Penghantaran Percuma',

      'checkout.payment_condition.title':
        'Still need {{difference}} to meet the checkout conditions!',
      'checkout.payment_condition.description':
        'Before proceeding to checkout, please return to the shopping cart to choose another payment method or continue shopping',
      'checkout.payment_condition.continue_shopping': 'Continue Shopping',
      'checkout.payment_condition.hint':
        'This order must meet the minimum amount in order to proceed to checkout',
      'checkout.payments.exist.order.tip':
        'There is an existing order <span class="order-number">{ {{order}} }</span> for the shopping cart. If you want to check out again, you can click Submit Order, or click the order number to view the original order.',
      'checkout.payments.exist.order.confirm': 'Confirm',
      'checkout.payments.exist.order.cancel': 'Cancel',
      'checkout.payments.exist.order.title':
        'Order Exists for This Shopping Cart',
      'checkout.payments.exist.order.description':
        'Viewing the Original Order Will Clear the Shopping Cart',

      'checkout.payments.apple_pay.not_supported':
        'Perkhidmatan Apple Pay tidak disokong oleh peranti ini, sila lihat <ul><li>Sila daftar masuk ke pelayar internet menerusi akaun Apple anda</li>' +
        '<li>Sila TAMBAH kaedah pembayaran tersebut ke Akaun Apple Pay anda</li><li>Sila NAIK TARAF peranti ini serta pelayar internet anda ke versi yang terbaru</li></ul>',
      'checkout.payments.google_pay.not_supported':
        'Perkhidmatan Google Pay tidak disokong oleh peranti ini, sila lihat <ul><li>Sila daftar masuk ke pelayar internet menerusi akaun Google anda</li>' +
        '<li>Sila TAMBAH kaedah pembayaran tersebut ke Akaun Google Pay anda</li><li>Sila NAIK TARAF peranti ini serta pelayar internet anda ke versi yang terbaru</li></ul>',
      'checkout.payment_failed': 'Transaksi ini gagal. Sila cuba semula.',
      'checkout.cart_over_limit_error':
        'The maximum number of items is 100, please adjust the quantity and purchase again',

      'checkout.auto_fill.popup.title': 'Enter your phone for fast checkout',
      'checkout.auto_fill.popup.continue': 'Continue',
      'checkout.auto_fill.popup.error': 'Please enter the correct format',
      'checkout.auto_fill.popup.cancel': 'Cancel',
      'checkout.auto_fill.toast.error':
        'If there is no record of the last order detected, the fast checkout data will not be brought in',

      'address.fields.recipient_name': 'Nama Penerima',
      'address.fields.address.city': 'Bandar',
      'address.fields.address.state': 'Daerah/Negeri/Wilayah',
      'address.fields.address.postcode': 'Poskod (jika berkenaan)',
      'address.fields.address.country': 'Negara',
      'address.fields.address.remarks': 'Ringkasan',

      'messages.form.send': 'Hantar',
      'action.add_photo': 'Muat Naik Gambar',
      'action.send': 'Hantar',
      'action.load_earlier': 'Muatkan Sebelum',
      'action.load_more': 'Muatkan Seterusnya',

      'users.fields.name': 'Nama Penuh',
      'users.fields.email': 'E-mel',
      'users.fields.mobile_phone_or_email': 'E-mel atau nombor telefon bimbit',
      'users.fields.password': 'Kata Laluan',
      'users.fields.name.placeholder': 'Nama Penuh',
      'users.fields.email.placeholder': 'E-mel',
      'users.fields.password.placeholder': 'Kata Laluan',
      'users.save.success': 'Perubahan sudah disimpan.',
      'users.save.email_verification.success':
        'Perubahan sudah disimpan. Alamat e-mel akan dikemas kini selepas pengesahan selesai.',
      'users.add.phone': 'Tambah nombor baharu',
      'users.add.phone.maximum':
        'Anda hanya boleh menambah sehingga 5 nombor telefon.',
      'users.error.phone.format': 'Nombor Telefon tidak sah',
      'users.error.phone.required': 'Nombor Telefon diperlukan',
      'users.add.delivery_address': 'Tambah alamat baharu',
      'users.add.delivery_address.maximum':
        'Anda hanya boleh menambah sehingga 5 alamat.',
      'users.error.not_save_mobile_phone':
        'Please verify this phone number before saving change.',
      'users.error.no_changes': 'Tiada perubahan untuk disimpan.',
      'users.error.maxlength': ' terlalu panjang.',
      'users.signup.male': 'Lelaki',
      'users.signup.female': 'Perempuan',
      'users.signup.other': 'Tidak diberitahu',
      'users.signup.email': 'Mendaftar melalui E-mel',
      'users.signup.mobile_phone': 'Mendaftar melalui nombor telefon bimbit',
      'users.signup.unconfirmed':
        'Selamat Kembali! Untuk melindungi akaun anda, pengesahan diperlukan untuk daftar masuk ke akaun anda. E-mel pengesahan sudah dihantar ke {{email}}. Sila semak peti surat anda. Terima kasih!',
      'users.signup.verification.hint.send':
        'E-mel pengesahan dihantar. Anda boleh menghantar semula e-mel baharu selepas {{count}} saat.',
      'users.einvoice_carrier': 'Jumlahkan E-invois ke dalam akaun anda',

      'membership.profile.subscriptions.orders.update.status':
        'Kemas kini untuk pesanan saya',
      'membership.profile.subscriptions.orders.comments.new':
        'Komen baharu daripada peniaga tentang pesanan saya',
      'membership.profile.subscriptions.messages.new':
        'Mesej baharu daripada peniaga',
      'membership.profile.subscriptions.marketing.news':
        'Berita dan promosi terbaru kedai',
      'membership.profile.subscriptions.none': 'Tiada',
      'membership.profile.mobile.number.verified':
        'Nombor telefon bimbit telah disahkan dan akan menggantikan nombor telefon yang anda simpan sebelum ini.',
      'membership.profile.check.code.error':
        'Kod pemeriksaan tidak betul! Sila masukkannya semula atau hantar semula sms kod pemeriksaan',

      'store_credits.title': 'Kredit Kedai',
      'store_credits.credit_balance': 'Baki Kredit',
      'store_credits.credit_history': 'Rekod Kredit',
      'store_credits.fields.date': 'Tarikh',
      'store_credits.fields.remarks': 'Sebab Perubahan Kredit',
      'store_credits.fields.value': 'Pergerakan Kredit',
      'store_credits.fields.expiry_date': 'Tarikh Luput',
      'store_credits.fields.balance': 'Baki Kredit',
      'store_credits.fields.order_prefix': 'Penggunaan kredit dalam pesanan',
      'store_credits.fields.welcome_credit': 'Kredit Aluan',
      'store_credits.fields.birthday_credit': 'Kredit Hari Jadi',
      'store_credits.fields.user_credit_expired': 'Kredit Luput',
      'store_credits.fields.never_expires': 'Tidak Akan Luput',
      'store_credits.fields.never_expires_point': 'Points Never Expired',
      'store_credits.fields.display.never_expired': 'Never Expired',
      'store_credits.fields.auto_reward_prefix': 'Order ',
      'store_credits.fields.auto_reward': ' gets reward credits',
      'store_credits.fields.revert_credit':
        'Kredit dikembalikan daripada pesanan yang dibatalkan',
      'store_credits.fields.revert_credit.return':
        'Credit Reverted from Returned order',
      'store_credits.fields.revert_credit.order_split':
        'Credits reverted from split order',
      'store_credits.fields.revert_credit.order_edit':
        'Credits reverted from edited order',
      'store_credits.fields.revert_credit.return_order_revert':
        'Reverted credits; from return',
      'store_credits.no_data': 'Tiada rekod kredit',

      'error.404.title': 'Kami minta maaf',
      'error.404.description': 'Halaman ini tidak wujud',
      'error.401.title': 'Kami minta maaf',
      'error.401.description': 'Anda tidak dibenarkan mengakses halaman ini',
      'error.403.title': 'Kami minta maaf',
      'error.403.description': 'Anda tidak dibenarkan mengakses halaman ini',
      'error.403.not_signed_in.message': 'kemungkinan sebab anda belum',
      'error.403.not_signed_in.anchor': 'daftar masuk',
      'error.continue': 'Teruskan membeli-belah sekarang!',

      'dynamic.delivery.fee.no.state.notice':
        'Kadar penghantaran akan dipaparkan di laman daftar keluar setelah alamat penghantaran diisikan.',
      'dynamic.delivery.fee.no.state.info':
        'Kadar penghantaran akan dipaparkan setelah alamat penghantaran disahkan',
      'dynamic.delivery.fee.calculate.title':
        'Kaedah pengiraan kadar penghantaran',
      'dynamic.delivery.fee.calculate.desc':
        'Kadar penghantaran untuk 1 kg pertama + (jumlah berat-1kg) * kadar tambahan berat penghantaran',
      'dynamic.delivery.fee.calculate.hint':
        'Sekiranya jumlah berat adalah kurang dari 1 kg, ia akan dikira sebagai 1 kg',
      'dynamic.delivery.fee.updated.notice':
        'Kadar penghantaran telah dikemaskini',
      'dynamic.delivery.fee.calculate.info':
        'jumlah penghantaran adalah: {{totalDeliveryFee}}',

      'orders.comments.title': 'Komen kedai dan pelanggan',
      'orders.comments.text.name': 'Komen',
      'orders.comments.text.placeholder': 'Masukkan komen untuk pesanan ini',

      'orders.fields.payment_fee': 'Caj Pembayaran',

      'orders.invoices.title': 'Invois',
      'orders.invoices.carrier': 'Pembawa Umum',
      'orders.invoices.carrier_type.member': 'Ahli',
      'orders.invoices.carrier_type.mobile_barcode': 'Barcode Telefon Bimbit',
      'orders.invoices.carrier_type.npc_barcode': 'Barcode NPC',
      'orders.invoices.get_invoice': 'Saya perlukan resit',

      'orders.notifications.title': 'Notifikasi Perkembangan Pesanan',

      'order.payments.login.confirm':
        'Please log in and go to pay again or update credit card information!',

      'model.credit_card.title': 'Maklumat Kad Kredit Anda',
      'model.credit_card.subtitle':
        'Sila masukkan kad kredit anda di bawah untuk mengemas kini',
      'model.credit_card.choose_card': 'Fast checkout with your saved card',
      'model.credit_card.use_new_card': 'Use a new card',
      'model.slpayment.security_agreement':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',
      'model.slpayment.recurring_pay_agreement':
        'We’ll use the payment details you just provided for subsequent recurrent payments. By submitting, you agree to the <a href="https://shopline.tw/about/product_subscription_agreements_en" target="_blank" rel="noopener">Term Purchase bank card Withholding Agreement.</a>',
      'model.update_credit_card_for_product_subscription.success':
        'Credit card information updated successfully',
      'model.update_credit_card_for_product_subscription.fail':
        'The payment information for Product Subscription is invalid. Please try again.',

      'facebook_messenger_checkbox.subscribed': 'Dihantar ke {{topic}} melalui',
      'facebook_messenger_checkbox.turn_off': 'Matikan',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        'notifikasi perkembangan pesanan',
      'facebook_messenger_checkbox.topics.MARKETING':
        'berita dan promosi terbaru kedai',

      'media.validation.type': 'hanya format jpeg dan png dibenarkan',
      'media.validation.error': 'Ralat memuat naik imej',

      'imagepicker.title': 'Muat Naik Imej',

      'promotions.section.discount_summary.set.on_item':
        'Dapatkan potongan diskaun sebanyak  <b>{{ discount_value }}</b> untuk <b>Produk Terpilih</b>',
      'promotions.section.discount_summary.set.on_order':
        'Dapatkan potongan diskaun sebanyak <b>{{ discount_value }}</b> untuk <b>Keseluruhan Pesanan</b>',
      'promotions.section.discount_summary.discount_value':
        '<b>{{ type == "amount" ? Currency : ""  }}{{ value }}{{ type == "percentage" ? "%":""  }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>Produk Terpilih</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>Keseluruhan Pesanan</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        ' dengan pembelian <b>Produk Terpilih</b> melebihi <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        ' dengan pembelian melebihi <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }} item',
      'promotions.section.discount_summary.criteria.bundle_group':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }}.',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' Buy more, Save more!',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption': 'potongan {{ value }}%',

      'product.page.title.product-info': 'Produk',
      'product.page.quantity': 'Kuantiti',
      'product.page.unit_price': 'Harga Seunit',
      'product.page.subtotal': 'Jumlah kasar',
      'product.page.add_to_cart_count':
        'Sudah ditambah <span class="order-detail-count">{{count}}</span> item',

      'products.quick_cart.quantity': 'Kuantiti',
      'products.quick_cart.preorder_now': 'PRA PESAN SEKARANG',
      'products.quick_cart.add_to_cart': 'MASUKKAN DALAM TROLI',
      'products.quick_cart.quantity_invalid': '',
      'products.quick_cart.out_of_stock': 'HABIS DIJUAL',
      'products.quick_cart.out_of_stock_hint':
        'Stok tidak mencukupi. Item anda tidak ditambah ke dalam troli.',
      'products.quick_cart.low_stock_hint':
        'Stok tidak mencukupi.\n Sila laraskan kuantiti anda.',
      'products.quick_cart.reached_max_purchase_quantity':
        'Had {{ message }} setiap pesanan.',
      'products.quick_cart.quantity_of_stock_hint':
        'Hanya {{message}} item yang tinggal.',
      'products.quick_cart.messagetobuy':
        'Sila hantar mesej kepada tuan kedai untuk butiran pesanan.',
      'products.quick_cart.message': 'MESEJ',
      'products.quick_cart.show_more': 'Butiran Lanjut',
      'products.message.title': 'Anda pasti mahu mengeluarkan produk ini?',
      'products.message.button_cancel': 'Batal',
      'products.message.button_ok': 'OK',
      'products.purchase_limit_hint': 'Limit {{limit}} per customer',
      'purchase_limit_hint.purchasable':
        'Limit {{limit}} per customer. You can only purchase {{purchasable_qty}} more.',
      'products.purchase_limit_hint_can_not_purchase':
        'Limit {{limit}} per customer. You have already purchased to limit.',

      'products.category.advance_filter.title': 'penapis',
      'products.category.advance_filter.clear': 'pemilihan yang jelas',
      'products.category.advance_filter.clear_all': 'Kosongkan semua',
      'products.category.advance_filter.apply': 'Laksanakan Penapis',
      'products.category.advance_filter.apply.hint':
        'Sila pilih pilihan penapis terlebih dahulu',
      'products.category.advance_filter.show_more': 'menunjukkan lebih',
      'products.category.advance_filter.show_less': 'Tunjukkan kurang',
      'products.category.advance_filter.type.color': 'Warna',
      'products.category.advance_filter.type.size': 'saiz',
      'products.category.advance_filter.type.brand': 'jenama',
      'products.category.advance_filter.type.material': 'bahan',
      'products.category.advance_filter.type.price':
        'Julat harga ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': 'min',
      'products.category.advance_filter.placeholder.max_price': 'Dagangan',

      'hk_sfplus.region': 'Rantau',
      'hk_sfplus.area': 'Kawasan',
      'hk_sfplus.district': 'Daerah',
      'hk_sfplus.address': '{{region}}, {{district}}, {{area}}',

      'tcat.time_slot.title': 'Slot Masa Ketibaan',
      'tcat.time_slot.01': 'Sebelum 13:00',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': 'Mana-mana slot masa',
      'verification.email.trial_merchant.reach_limit.failure':
        'The verification email is not available during the trial operation of the store. If needed, please contact the store for assistance.',
    });
  },
]);
